import * as React from "react";

import { PropertyTypesCarousel as PropertyTypesCarouselSharedUI } from "@shared-ui/retail-storefront-property-types-carousel";
import { PropertyTypesCarouselProps } from "./typings";
import { EGDSSpacing } from "@egds/react-core/spacing";

export const PropertyTypesCarousel: React.FC<PropertyTypesCarouselProps> = (props) => {
  const { templateComponent } = props;

  /* istanbul ignore if */
  if (!templateComponent) {
    return null;
  }

  const {
    configurationId,
    destinationId,
    mobileTitleSize,
    blockPadding,
    skipSsr,
    enableSkeleton,
  } = templateComponent.config;

  return (
    <EGDSSpacing padding={{ block: blockPadding }}>
      <div id="property-type-carousel">
        <PropertyTypesCarouselSharedUI
          inputs={{ configurationId, destinationId }}
          enableSkeleton={enableSkeleton}
          titleSize={{ small: mobileTitleSize }}
          skipSsr={skipSsr}
        />
      </div>
    </EGDSSpacing>
  );
};

export default PropertyTypesCarousel;
